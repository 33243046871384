:root {
  --primaryColor: #374B4A;
  --secondaryColor: #D1CCD1;
  --backgroundColor: #526760;
  --successColor: #3fa34d;
  --failColor: #f50010;
  --jikaColor: #3e6fed;
}

@import url(./vendor/normalize.css);
@import url(./blocks/page/page.css);
@import url(./blocks/header/header.css);
@import url(./blocks/blog/blog.css);
@import url(./blocks/about/about.css);
@import url(./blocks/examples/examples.css);
@import url(./blocks/popup/popup.css);