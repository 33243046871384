.blog {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 50px 0;
  display: flex;
  flex-wrap: wrap;
}

.blog__title {
  padding: 5px 40px 5px 0;
  margin: 0;
  font-size: 48px;
  font-weight: 700;
}

.blog__post {
  box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  padding: 0 50px 50px;
  display: flex;
  flex-direction: column;
}

.blog__posts {
  padding: 0;
  margin: 0;
}

.blog__posts-item-link-wrapper {
  text-decoration: none;
  color: var(--secondaryColor);
  margin: 5px;
  padding: 20px;
}

.blog__posts-item-link-wrapper:hover {
  margin-bottom: 4px;
  border-bottom: 1px solid var(--secondaryColor);
}

.blog__posts-item {
  display: flex;
  flex-direction: column;
}

.blog__posts-item-title {
  padding: 0 0 10px 0;
  margin: 0;
}

.blog__posts-item-content {
  padding: 0 0 10px 0;
  margin: 0;
}

.blog__posts-item-details {
  padding: 5px 0;
  display: flex;
}

.blog__posts-item-details > li {
  position: relative;
  margin: 0 20px 0 0;
}

.blog__posts-item-details > li:nth-child(n + 2) {
  margin: 0 20px;
}

.blog__posts-item-details > li:nth-child(n + 2)::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: var(--secondaryColor);
  border-radius: 50%;
}

/* Blog Create Post */

.blog-create {
  box-sizing: border-box;
  max-height: calc(100vh - 180px);
  overflow: scroll;
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
  display: flex;
}

.blog-create__main {
  width: calc(100% - 300px);
  min-width: 500px;
  max-height: calc(100vh - 180px);
  overflow: scroll;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.blog-create__input {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}

.blog-create__input > input {
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 2px 2px var(--secondaryColor);
}

.blog-create__elements {
  min-width: 300px;
  max-height: 100%;
  overflow-y: scroll;
}

.blog-create__element {
  display: flex;
  flex-direction: column;
}

.blog-create__choose-gallery {
  cursor: pointer;
  border: none;
  background-color: var(--secondaryColor);
  padding: 10px;
  width: fit-content;
  border-radius: 5px;
  color: var(--primaryColor);
  opacity: 0.8;
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blog-create__choose-gallery:hover {
  opacity: 1;
}

.blog-create__element-button {
  margin: 10px 0;
}

.blog-create__element-header {
  margin: 20px 0;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-create__element-id {
  margin: 0;
  font-size: 18px;
}

.blog-create__element-remove {
  height: fit-content;
  border: none;
  background-color: transparent;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.blog-create__element-item {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.blog-create__element-item-name,
.blog-create__element-item-style-name {
  margin: 0;
}

.blog-create__element-item-value > input,
.blog-create__element-item-value > select,
.blog-create__element-item-value > textarea {
  width: 150px;
  padding: 10px 20px;
  margin: 0 0 0 10px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 0 2px 2px var(--secondaryColor);
}

.blog-create__element-item-styles {
  box-sizing: border-box;
  padding: 0 20px;
  min-width: calc(100% - 40px);
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.blog-create__element-item-styles-show {
  cursor: pointer;
  width: fit-content;
  margin: 10px auto 20px auto;
  padding: 10px 30px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  box-shadow: 0 0 3px 2px var(--secondaryColor);
  border-radius: 15px;
}

.blog-create__element-item-style {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-create__element-item-style-name {
  font-weight: 700;
  font-size: 18px;
}

.blog-create__element-item-style > label > input {
  padding: 2px 5px;
  width: 60px;
}

.blog-create__footer {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.blog-create__submit-button {
  height: 50px;
  width: 80%;
  max-width: 1200px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: var(--secondaryColor);
  box-shadow: 0 0 3px 2px var(--secondaryColor);
  padding: 5px 15px;
  border-radius: 15px;
  font-weight: 700;
}

.blog-create__submit-button,
.blog-create__element-item-styles-show {
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.blog-create__element-item-styles-show:hover {
  box-shadow: 0 0 3px 3px var(--secondaryColor);
  opacity: 0.8;
}

.blog-create__submit-button:hover {
  box-shadow: 0 0 3px 5px var(--secondaryColor);
  opacity: 0.8;
}

.blog-create__element-item-value.tag > select {
  width: 150px;
}

.blog__post-reactions-header {
  display: flex;
  justify-content: flex-start;
}

.blog__post-reactions-title-wrapper {
  border: none;
  background-color: transparent;
  color: var(--secondaryColor);
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.blog__post-reactions-title-wrapper:first-of-type {
  margin: 0;
}

.blog__post-reactions-title-icon {
  margin-right: 10px;
  opacity: 0.4;
  transition: opacity 0.5 cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blog__post-reactions-title-wrapper:hover .blog__post-reactions-title-icon,
.blog__post-reactions-title-wrapper.active .blog__post-reactions-title-icon {
  opacity: 1;
}

.blog__post-reactions-list {
  display: flex;
  flex-direction: column;
}

.blog__post-add-comment {
  display: flex;
}

.blog__post-add-comment-label > input {
  border: none;
  background-color: var(--secondaryColor);
  border-radius: 5px;
  padding: 10px 15px;
  color: var(--primaryColor);
  font-weight: 700;
}

.blog__post-add-comment-label > input::placeholder {
  color: var(--primaryColor);
  opacity: 0.6;
}

.blog__post-add-comment-button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
  padding: 5px;
  opacity: 0.8;
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.blog__post-add-comment-button:hover {
  opacity: 1;
}

.blog__post-reactions-comment {
  position: relative;
  width: fit-content;
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
  padding: 10px;
  margin: 10px 0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.blog__post-reactions-comment > ul {
  font-size: 14px;
  padding: 5px;
}

.blog__post-reactions-comment > ul > li {
  margin: 0 10px 0 0;
}

.blog__post-reactions-comment > ul > li:nth-child(n + 2) {
  margin: 0 10px;
}

.blog__post-reactions-comment > ul > li:nth-child(n + 2)::before {
  left: -15px;
  background-color: var(--primaryColor);
}

.blog__post-reactions-comment-message {
  margin: 5px 0;
}

.blog__post-remove-comment-button {
  padding: 0;
  margin: 5px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  color: var(--primaryColor);
  font-weight: 700;
}