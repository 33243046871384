.header {
  z-index: 5;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 68px;
  padding: 10px 0;
  background-color: var(--backgroundColor);
  border-bottom: 2px solid var(--secondaryColor);
  overflow: scroll;
}

.header__nav-user-container {
  margin: 0 30px 0 auto;
}

.header__nav-user {
  cursor: pointer;
  height: 100%;
  border: none;
  background-color: transparent;
  color: var(--secondaryColor);
  font-size: 22px;
  font-weight: 700;
}

.header__nav-links {
  margin: 0 0 0 50px;
  display: flex;
}

.header__nav-link {
  margin: 0 25px;
  padding: 5px;
}

.header__nav-link:first-of-type {
  margin: 0 25px 0 0;
}

.header__nav-link_link {
  padding-bottom: 1px;
  text-decoration: none;
  color: var(--secondaryColor);
  font-size: 32px;
  font-weight: 700;
  opacity: 0.7;
}

.header__nav-link_link.active,
.header__nav-link_link:hover {
  opacity: 1;
  border-bottom: 3px solid rgba(7, 59, 28, 0.5);
}
