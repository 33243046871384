.page {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
  box-sizing: border-box;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  position: relative;
  font-family: 'Rubik', sans-serif;
  min-height: 100vh;
}

.main {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
