.popup {
  text-align: left;
  color: var(--blackColor);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out, pointer-events 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  content: ' ';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 15;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.popup__container {
  display: inline-flex;
  flex-direction: column;
  margin-top: 207px;
  box-sizing: border-box;
  position: relative;
  width: 650px;
  background-color: var(--secondaryColor);
  border-radius: 16px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  padding: 34px 12px 15px 12px;
  overflow: hidden;
}

.popup__main {
  min-height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}

.popup__info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  box-sizing: border-box;
  position: relative;
  background-color: var(--secondaryColor);
  border-radius: 16px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  padding: 14px 62px 28px 62px;
}

.popup__info-main {
  display: inline-flex;
  flex-direction: column;
}

.popup__info-profile-image {
  margin: 10px 0 30px 0;
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  color: var(--blackColor);
  background-image: url('../../images/no-profile.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  font-weight: 800;
  overflow: hidden;
}

.popup__info-profile-image-overlay {
  position: absolute;
  display: none;
  background-color: var(--secondaryColor);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  opacity: 0.6;
}

.popup__info-profile-image-text {
  margin: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.popup__info-profile-image:hover {
  font-size: 18px;
  cursor: pointer;
}

.popup__info-profile-image:hover .popup__info-profile-image-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__info-input-file {
  display: none;
}

@media screen and (max-width: 945px) {
  .popup__info-profile-image {
  }
}

.popup__info-image {
  width: 100px;
  height: 100px;
  margin-right: 15px;
}

.popup__info-title {
  margin: 0 0 10px 0;
  font-size: 32px;
  font-weight: 700;
}

.popup__info-content-container {
  display: block;
  padding: 15px 15px 0 0;
}

.popup__info-friend-name {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup__info-content {
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
}

.popup__container_type_info {
  padding: 34px 36px 50px 36px;
}

.popup__form {
  height: 100%;
  padding: 20px 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup__title {
  padding: 0 10px;
  margin: 0 0 22px 0;
  font-weight: 900;
  font-size: 32px;
  line-height: 28px;
  color: var(--primaryColor);
}

.popup__gallery {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: scroll;
}

.popup__gallery-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup__gallery-item-title {
  margin: 0;
  color: var(--primaryColor);
}

.popup__gallery-item-button {
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  background-color: transparent;
  border: 5px solid transparent;
  border-radius: 15px;
}

.popup__gallery-item-button:hover {
  border: 5px solid var(--backgroundColor);
}

.popup__gallery-item {
  border-radius: 15px;
}

.popup__label {
  margin: 10px 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 15px;
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup__label.file {
  pointer-events: none;
  cursor: pointer;
}

.popup__label.file input {
  display: none;
}

.popup__label.file::after {
  pointer-events: auto;
  content: 'Upload File';
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 200px;
  height: 32px;
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
  border-radius: 15px;
  background-color: var(--backgroundColor);
  color: var(--secondaryColor);
}

.popup__label-preview {
  margin: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup__label > input {
  padding: 0 10px;
  margin: 20px 0;
  width: 200px;
  height: 32px;
  border: none;
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
  border-radius: 15px;
  background-color: var(--backgroundColor);
  color: var(--secondaryColor);
  box-shadow: 0 0 2px 3px var(--secondaryColor);
  outline: none;
}

.popup__label-error {
  display: inline-block;
  color: var(--failColor);
  font-size: 16px;
  min-height: 15px;
  min-width: 15px;
  margin: 5px 0;
}

.popup__error {
  display: inline-block;
  color: var(--failColor);
  font-size: 12px;
  font-weight: normal;
  line-height: 1.25;
  min-height: 15px;
  min-width: 15px;
  margin: 3px 0 10px 0;
}

.popup__form-error {
  display: inline-block;
  color: var(--failColor);
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.25;
  min-height: 15px;
  min-width: 15px;
  margin: 3px 0 8px 0;
}

.popup__save-button {
  height: 50px;
  width: 100%;
  padding: 0;
  margin-top: 20px;
  color: #fff;
  background: var(--backgroundColor);
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  border: none;
  opacity: 1;
  transition: background 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.popup__save-button:hover {
  cursor: pointer;
  background-color: var(--primaryColor);
}

.popup__save-button_disabled {
  pointer-events: none;
  background-color: #e6e8eb;
  color: #b6bcbf;
}

.popup__title_type_info {
  font-size: 18px;
  line-height: 22px;
  display: flex;
}

.popup__bottom-link {
  font-size: 26px;
  font-weight: 700;
  border: none;
  width: fit-content;
  margin: 0 auto;
  border-radius: 25px;
  padding: 5px 10px;
  box-shadow: 0 0 2px 3px var(--blackColor);
  background-color: transparent;
  color: var(--primaryColor);
  text-decoration: none;
  cursor: pointer;
}

.popup__bottom-link:hover {
  box-shadow: 0 0 2px 4px var(--blackColor);
}

.popup__close-button {
  background-image: url('../../images/close-icon.svg');
  background-color: rgba(0, 0, 0, 0);
  background-size: cover;
  height: 40px;
  width: 40px;
  border: none;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-transition: opacity 0.1s ease-out 0.1s;
  -o-transition: opacity 0.1s ease-out 0.1s;
  transition: opacity 0.1s ease-out 0.1s;
}

.popup__close-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.popup__message {
  margin: 0;
  padding: 14px 0;
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.popup__compose-user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.popup__compose-user-cards {
  overflow: scroll;
}

.popup__compose-user-name {
  margin: 0 auto 0 0;
  font-size: 24px;
  /* Calculating 100% - image + button width */
  max-width: calc(100% - 250px);
}

.popup__compose-user-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.popup__compose-send-button {
  padding: 0 10px;
  border: 1px solid var(--blackColor);
  background-color: var(--blueColor);
  border-radius: 25px;
  box-shadow: 0 0 2px 1px var(--blackColor);
  height: 50px;
  width: 120px;
  opacity: 1;
}

.popup__compose-send-button:hover {
  box-shadow: 0 0 2px 2px var(--blackColor);
  cursor: pointer;
}

.popup__compose-send-button-text {
  color: var(--blackColor);
  margin: 0;
  font-size: 16px;
  transition: font 0.1s cubic-bezier(0.19, 1, 0.12, 1);
}

.popup__compose-send-button:hover .popup__compose-send-button-text {
  font-size: 18px;
}

.popup__new-group-form {
  height: 100%;
  display: flex;
  justify-content: center;
}

.popup__new-group-details {
  width: 352px;
  padding-left: 15px;
  margin-right: 30px;
}

.popup__new-group-subtitle {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 700;
}

.popup__new-group-name-input {
  width: 80%;
  padding: 5px 10px;
  margin-top: 15px;
  border-radius: 25px;
  border: 1px solid var(--blackColor);
  box-shadow: 0 0 1px 2px var(--blackColor);
  font-size: 18px;
  line-height: 1.4;
  color: var(--blackColor);
}

.popup__new-group-subtitle:nth-child(2) {
  margin: 20px 0 0 0;
}

.popup__new-group-image {
  margin: 10px 0 20px 0;
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  color: var(--blackColor);
  background-image: url('../../images/no-profile.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  font-weight: 800;
  overflow: hidden;
}

.popup__new-group-image-overlay {
  position: absolute;
  display: none;
  background-color: var(--whiteColor);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  opacity: 0.6;
}

.popup__new-group-image-text {
  margin: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.popup__new-group-image:hover {
  font-size: 28px;
  cursor: pointer;
}

.popup__new-group-image:hover .popup__new-group-image-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__new-group-image-input {
  display: none;
}

.popup__new-group-friends {
  display: flex;
  flex-direction: column;
  min-width: 230px;
}

.popup__new-group-friends-title {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}

.popup__new-group-friends-container {
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.popup__new-group-friend-card {
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.popup__new-group-friend-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.popup__new-group-friend-column {
  padding-top: 10px;
}

.popup__new-group-friend-name {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: 500;
}

.popup__new-group-friend-add-button {
  padding: 0 10px;
  color: var(--blackColor);
  margin: 0;
  font-size: 16px;
  transition: box-shadw 0.35s cubic-bezier(0.19, 1, 0.12, 1);
  border: 1px solid var(--blackColor);
  background-color: var(--blueColor);
  border-radius: 25px;
  box-shadow: 0 0 2px 1px var(--blackColor);
  height: 40px;
  width: 100px;
  opacity: 1;
}

.popup__new-group-friend-add-button:hover {
  box-shadow: 0 0 2px 2px var(--blackColor);
  cursor: pointer;
}

.popup__new-group-selcted-friends {
  overflow: scroll;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  min-height: 50px;
  max-height: 100px;
  width: 90%;
  background-color: var(--greyColor);
  border-radius: 25px;
  border: 1px solid var(--blackColor);
  box-shadow: 0 0 2px 1px var(--blackColor);
}

.popup__new-group-selcted-friend {
  display: flex;
  align-items: center;
  margin: 0 3px 5px;
  width: 28%;
  height: 20px;
  padding: 2px 4px;
  background-color: var(--whiteColor);
  border-radius: 25px;
}

.popup__new-group-selcted-friend-name {
  margin: 0 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
}

.popup__new-group-diselect-friend-button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.popup__new-group-diselect-friend-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.popup__new-group-diselect-friend-icon {
  height: 20px;
  width: 20px;
}

.popup__new-group-submit-button {
  padding: 0 10px;
  color: var(--blackColor);
  margin: 10px 0 0 0;
  font-size: 18px;
  font-weight: 700;
  transition: box-shadw 0.35s cubic-bezier(0.19, 1, 0.12, 1);
  border: 1px solid var(--blackColor);
  background-color: var(--blueColor);
  border-radius: 25px;
  box-shadow: 0 0 2px 1px var(--blackColor);
  height: 40px;
  width: 250px;
  opacity: 1;
}

.popup__new-group-submit-button:hover {
  box-shadow: 0 0 2px 2px var(--blackColor);
  cursor: pointer;
}

.popup__new-group-error {
  color: var(--redColor);
  margin: 15px 0 0 10px;
}

.popup__main_hidden {
  visibility: hidden;
}

@media screen and (max-width: 495px) {
  .popup__container {
    width: 100vw;
    margin-top: 56px;
    padding: 16px;
  }

  .popup__title {
    margin: 0 0 18px 0;
    font-size: 24px;
    line-height: 28px;
  }

  .popup__save-button {
    font-size: 14px;
    width: 288px;
    height: 64px;
    margin: 0 auto;
  }

  .popup__close-button {
    right: 16px;
    top: 16px;
    width: 36px;
    height: 36px;
  }
}
