.console-command {
  overflow: hidden;
  background-color: black;
  padding: 10px;
  font-weight: 700;
  border-radius: 5px;
}

.code-example {
  overflow: hidden;
  background-color: rgb(29, 29, 30);
  padding: 20px;
  font-weight: 700;
  border-radius: 15px;
}

.code-example__function {
  color: rgb(226, 214, 118);
}

.code-example__const {
  color: rgb(59, 123, 207);
}

.code-example__green {
  color: rgb(115, 168, 132);
}

.code-example__default {
  color: rgb(118, 193, 228);
}

.code-example__text {
  color: rgb(238, 147, 29);
}

.code-example__number {
  color: rgb(181, 237, 187);
}

.code-example__comment {
  color: rgb(24, 150, 66);
}