.about {
  box-sizing: border-box;
  width: 100%;
  padding: 30px 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__title {
  margin: 0;
  font-weight: 700;
  font-size: 48px;
}

.about__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__brief {
  margin: 20px 0;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.2;
}

.about__brief_jika {
  text-decoration: none;
  font-weight: 700;
  color: var(--jikaColor);
}

.about__profile-img {
  border-radius: 50%;
}

.about__portfolio-link {
  margin: 20px 0;
  padding: 10px 20px;
  background-color: var(--secondaryColor);
  border-radius: 15px;
  width: fit-content;
  font-size: 32px;
  font-weight: 700;
  text-decoration: none;
  color: var(--primaryColor);
  opacity: 0.7;
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.about__portfolio-link:hover {
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .about {
    padding: 40px 40px 60px 40px;
  }

  .about__image {
    width: 232px;
    height: 232px;
    margin-top: 20px;
  }

  .about__title {
    font-size: 30px;
    line-height: 40px;
    padding-top: 0;
  }

  .about__brief {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .about {
    flex-direction: column;
    align-items: center;
    width: 380px;
    margin: 0 auto;
    padding: 32px 16px 128px 16px;
  }

  .about__image {
    margin: 0;
  }

  .about__title {
    padding: 16px 0 24px 0;
  }

  .about__text {
    width: 348px;
    padding: 0;
  }
}

@media screen and (max-width: 495px) {
  .about {
    width: 288px;
  }

  .about__text {
    width: 288px;
  }
}
